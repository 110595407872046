@import "../node_modules/bootstrap/scss/bootstrap.scss";
@import '../node_modules/bootstrap-icons/font/bootstrap-icons.scss';
@import "../node_modules/@splidejs/splide/src/css/themes/skyblue/index.scss";

.card-body a{
    text-decoration: none;
}
.price{
    padding: 2px 12px;
    right: 0px;
    bottom: 20px;
    font-size: 22px;
    color: white;
    font-weight: 600;
    z-index: 100;
    position: absolute;
}

.tag {
    float: left;
    position: absolute;
    transform: rotate(-45deg);
    left: -60px;
    top: 30px;
    text-align: center;
    width: 200px;
    font-size: 14px;
    margin: 0;
    color: #fff;
    font-weight: 500;
    line-height: 32px;
    text-transform: uppercase;
}

.cover-image {
    position: relative;
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

/* Splide */

.splide__slide img {
  width: 100%;
  height: auto;
}