@use '../../foundation/colors';

$border: 3px solid transparent !default;
$border-active: 3px solid colors.$main !default;
$border-radius: false !default;
$opacity: false !default;
$opacity-active: false !default;

.splide {
  $root: &;

  &--nav {
    > #{ $root }__slider > #{ $root }__track,
    > #{ $root }__track {
      > #{ $root }__list {
        > #{ $root }__slide {
          border: $border;
          cursor: pointer;

          @if $opacity {
            opacity: $opacity;
          }

          @if $border-radius {
            border-radius: $border-radius;
          }

          &.is-active {
            border: $border-active;

            @if $opacity {
              opacity: $opacity-active;
            }
          }

          &:focus {
            outline: none;
          }
        }
      }
    }
  }
}
